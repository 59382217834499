.header {
  text-align: center;
  font-family: "Varela", sans-serif;
}

.row {
  display: flex;
  text-align: center;
  font-family: "Varela", sans-serif;
}
  
.column {
  flex: 50%;
  padding: 8vw;
  font-family: "Varela", sans-serif;
}

.pic {
  height: 250px;
  width: 250px;
}