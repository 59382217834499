.logo {
    display: inline-block;
    width: 15%;
    font-size: 1vw;
}

.logo img {
    width: 100%;
}

.page {
    width: 100%;
    text-align: center;
}

.filters{
    width: 50%;
    margin: auto;
}

.download {
    margin-top: 30px;
    padding: 15px 30px;
}

.button_link {
    text-decoration: none;
    
}