.landing {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    font-family: "Varela", sans-serif;
    font-size: 1vw;
  }
  
  .intro {
    border-radius: 50%;
    text-align: center;
    margin: auto;
    height: auto;
    width: 50%;
    color: white;
    background-color: #e3292c;
  }

  .intro a{
    display: inline-block;
  }
  